<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-input type="text" v-model="q.answer[0].data" class="input-lg"/>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q98',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '특이사항',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '특이사항',
        answer: [
          {
            data: '',
          },
        ],
      };
    }
  },
};
</script>